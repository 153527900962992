import React, { useState } from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link, useLocation } from "react-router-dom";
import service from "../../assets/images/resource/rpa.jpg";
import { Helmet } from "react-helmet-async";
import basebg from "../../assets/images/resource/base.png";
import g1 from "../../assets/images/resource/rpa-new.png";

const RpaTraining = () => {
  pageTitle("RPA with AI Course");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    setFormData({
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    });
  };

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const servicesData = [
    { id: 5, title: "RPA & AI", slug: "/rpa-training" },
    {
      id: 1,
      title: "Cloudera Data Engineering",
      slug: "/data-engineering-course",
    },
    { id: 2, title: "Cloudera Machine Learning", slug: "/machine-learning" },
    { id: 3, title: "Cloudera Data analyst", slug: "/data-analyst" },
    { id: 4, title: "Cloudera Administrator", slug: "/cloud" },
    { id: 6, title: "Just enough Python", slug: "/python" },
  ];

  const renderServices = () => {
    return servicesData.map((service) => (
      <li
        key={service.id}
        className={service.slug === "/rpa-training" ? "active" : ""}
      >
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  // Helper to extract YouTube video ID from Shorts URL

  const getYouTubeVideoId = (url) => {
    const parts = url.split("/");
    let id = parts[parts.length - 1];
    // Remove query parameters if present
    if (id.includes("?")) {
      id = id.split("?")[0];
    }
    return id;
  };

  // Data for the 16 course details (replace topics and links with your actual content)
  const courseDetails = [
    {
      number: 1,
      title: "Introduction to Robotic Process Automation (RPA)",
      topics:
        "TOPICS COVERED: What RPA Is and Is Not, RPA vs. Test Automation, UiPath RPA Platform Overview, Power Automate RPA Platform, RPA’s Impact on Outsourcing/Offshoring",
      youtubeShort:
        "https://youtube.com/shorts/DL2n8u-rl4Q?si=BlmnNiWIA4g_705G",
    },
    {
      number: 2,
      title: "Deeper Understanding of RPA",
      topics:
        "TOPICS COVERED: Industries & Functions Best Suited for Automation, RPA Benefits, Challenges, and Risks, RPA Market Growth & Potential, Overview of Leading RPA Tools, RPA Roles & Job Opportunities",
      youtubeShort: "https://youtube.com/shorts/LuDbBJa5yug?si=Nb7NEseFZ9ASvMhp",
    },
    {
      number: 3,
      title: "Implementing and Installing RPA",
      topics:
        "TOPICS COVERED: Introduction to Automation Implementation, Ranking RPA Ideas for Value, Building a Business Case for Automation, Installing UiPath Studio",
      youtubeShort: "https://youtube.com/shorts/O3KTUaYC3Bw?si=D5lwJTButPywWEnl",
    },
    {
      number: 4,
      title: "Variables, Arguments, and Data Types",
      topics:
        "TOPICS COVERED: Understanding Variables and Scope, Using Arguments in Workflows, Common Data Types, Creating a Workflow with Variables and Arguments",
      youtubeShort: "https://youtube.com/shorts/wHZBNT6dGEE?si=py5WLJQf9bfBv3Dk",
    },
    {
      number: 5,
      title: "Control Flow in UiPath",
      topics:
        "TOPICS COVERED: If-Else and Switch Activities, Looping Constructs, Loop Control: Break and Continue, Processing a Data List with Conditions",
      youtubeShort: "https://youtube.com/shorts/dfNAppf3NpM?si=MnpxFgxw84ADa_-g",
    },
    {
      number: 6,
      title: "UI Automation",
      topics:
        "TOPICS COVERED: Basic Concepts of UI Automation, Using Click, Type Into, Get Text, Select Item Activities, Anchor Base and Image Automation, Automating Form Filling",
      youtubeShort: "https://youtube.com/shorts/kCb2fWx8TyA?si=C2Law_iwG9xfz5A1",
    },
    {
      number: 7,
      title: "Selectors and Wildcards",
      topics:
        "TOPICS COVERED: Understanding and Configuring Selectors, Using Wildcards and Variables in Selectors, Optimizing Selectors for Reliable Automation",
      youtubeShort: "https://youtube.com/shorts/ycX9sB5oCxA?si=Mv9bOoF8a_8ZMzz8",
    },
    {
      number: 8,
      title: "Excel Automation",
      topics:
        "TOPICS COVERED: Introduction to Excel Automation, Using Excel Application Scope, Read/Write Range, Append Range, Data Table Manipulation in Excel",
      youtubeShort: "https://youtube.com/shorts/JSsvcJL2s4Y?si=cOc4HzzI5d1NOJal",
    },
    {
      number: 9,
      title: "Advanced Excel Automation & Data Manipulation",
      topics:
        "TOPICS COVERED: Advanced Excel Tasks: Formulas, Filtering, Sorting, Data Table Manipulation, Complex Data Processing in Excel",
      youtubeShort: "https://youtube.com/shorts/2o9EKWttKq8?si=eVt2zMuKLoxGPXmY",
    },
    {
      number: 10,
      title: "File Handling & Directories",
      topics:
        "TOPICS COVERED: Managing Files and Folders in UiPath, Copy, Delete, Move Files, Handling Multiple Files and Directories, Automating File Sorting and Backup",
      youtubeShort: "https://youtube.com/shorts/-mIirDHSqQE?si=SYSvWrWX1K2CQyWQ",
    },
    {
      number: 11,
      title: "PDF Automation",
      topics:
        "TOPICS COVERED: Introduction to PDF Activities in UiPath, Extracting Text from PDFs, Handling Structured Data in PDFs, Automating Data Extraction from PDFs",
      youtubeShort: "https://youtube.com/shorts/rV2fWrD0Zzk?si=I8laX2Kj9a2AsN_m",
    },
    {
      number: 12,
      title: "Email Automation",
      topics:
        "TOPICS COVERED: Overview of Email Automation, Sending/Receiving Emails, Downloading Attachments, Automating Status Report Emails",
      youtubeShort: "https://youtube.com/shorts/gisfmOuLdKo?si=iBfgf97wwWlV8ASi",
    },
    {
      number: 13,
      title: "Web Automation",
      topics:
        "TOPICS COVERED: Web Automation Basics, Browser Automation with UiPath, Handling Pop-Ups and File Downloads, Automating Web Data Extraction",
      youtubeShort: "https://youtube.com/shorts/0l4hbF8wOkU?si=bD2KMP7cuzMYbe2k",
    },
    {
      number: 14,
      title: "Introduction to Recordings",
      topics: "TOPICS COVERED: Types of Recordings (Basic, Web), Benefits of Using Recordings",
      youtubeShort: "https://youtube.com/shorts/ycX9sB5oCxA?si=0eOs5KU6dL-Dovaa",
    },
    {
      number: 15,
      title: "Troubleshooting in UiPath",
      topics:
        "TOPICS COVERED: Resolving Selector Issues, Handling 'Element Not Found' Errors, Addressing Timeout and Data Errors, Debugging Techniques",
      youtubeShort: "https://youtube.com/shorts/WOdvdreMJP0?si=5j0Zr3gAfS4mGzXo",
    },
    {
      number: 16,
      title: "Capstone Project",
      topics: "TOPICS COVERED: Applying RPA Skills in a Real-World Scenario",
      youtubeShort: "https://youtube.com/shorts/4IqVTLfeB6I?si=qDAXgXcnCS7wh7-3",
    },
  ];

  return (
    <>
      <Helmet>
        <title>RPA with AI Course | Learn Robotic Process Automation</title>
        <meta
          name="description"
          content="Enroll in our RPA with AI course to learn the fundamentals of Robotic Process Automation (RPA) and AI. Practical hands-on training with RPA tools and AI-enhanced automation techniques."
        />
        <meta
          name="keywords"
          content="RPA, AI, Robotic Process Automation, Automation Training, AI in Automation, RPA Course"
        />
        <meta
          property="og:title"
          content="RPA with AI Course | Learn Robotic Process Automation"
        />
        <meta
          property="og:description"
          content="Enroll in our RPA with AI course to learn the fundamentals of Robotic Process Automation (RPA) and AI. Practical hands-on training with RPA tools and AI-enhanced automation techniques."
        />
        <meta property="og:image" content={g1} />
        <meta property="og:url" content="https://siw.sg/rpa-training" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="RPA with AI Course | Learn Robotic Process Automation"
        />
        <meta
          name="twitter:description"
          content="Enroll in our RPA with AI course to learn the fundamentals of Robotic Process Automation (RPA) and AI. Practical hands-on training with RPA tools and AI-enhanced automation techniques."
        />
        <meta name="twitter:image" content={g1} />
      </Helmet>
      <HeaderOne />

      <section className="page__title p_relative">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${g1})` }}
        ></div>
        <div className="auto-container">
          <div className="content__box p_relative">
            {/* Breadcrumb & Title if needed */}
          </div>
        </div>
      </section>

      <div className="service__details p_relative">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="Service" />
                </figure>

                <h2>
                  <span>
                    Robotic Process Automation (RPA) with AI Course Overview
                  </span>
                </h2>

                <p>
                  Robotic Process Automation (RPA) with Artificial Intelligence
                  (AI) introduces powerful automation solutions for tasks
                  ranging from rule-based workflows to more complex
                  decision-making processes...
                </p>

                <br />

                <h3>
                  <span>What You’ll Learn</span>
                </h3>
                <ul>
                  <li>
                    RPA fundamentals and AI-enhanced automation techniques
                  </li>
                  <li>Task and process identification for automation</li>
                  <li>
                    Bot development, deployment strategies, and monitoring for
                    continuous improvement
                  </li>
                  <li>
                    AI models, NLP, and machine learning algorithms to enhance
                    RPA workflows
                  </li>
                </ul>

                <br />

                <h3>
                  <span>What to Expect</span>
                </h3>
                <p>
                  This course is ideal for aspiring RPA and AI specialists and
                  any professionals looking to expand their understanding of
                  intelligent automation...
                </p>

                <br />

                <h3>
                  <span>Course Details</span>
                </h3>
                <ul
                  className="course-details-list"
                  style={{ listStyle: "none", padding: 0 }}
                >
                  {courseDetails.map((item) => {
                    const videoId = getYouTubeVideoId(item.youtubeShort);
                    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
                    return (
                      <li
                        key={item.number}
                        className="course-detail-item"
                        style={{
                          display: "flex",
                          alignItems: "start",
                          marginBottom: "20px",
                          background: "#f9f9f9",
                          padding: "15px",
                          borderRadius: "8px",
                        }}
                      >
                        <div style={{ flex: 1, paddingRight: "30px" }}>
                          <h5
                            style={{ margin: "0 0 10px", fontWeight: "bold" }}
                          >
                            {item.number}. {item.title}
                          </h5>
                          <p style={{ margin: 0 }}>{item.topics}</p>
                        </div>
                        <div
                          className="course-detail-video"
                          style={{
                            position: "relative",
                            minWidth: "120px",
                            marginLeft: "20px",
                          }}
                        >
                          <a
                            href={item.youtubeShort}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ display: "block", position: "relative" }}
                          >
                            <img
                              src={thumbnailUrl}
                              alt={`Youtube Short for ${item.title}`}
                              style={{
                                width: "150px",
                                height: "auto",
                                borderRadius: "4px",
                              }}
                            />
                            <div
                              className="play-button"
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                zIndex: 2,
                              }}
                            >
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="#fff"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <polygon points="12,8 24,16 12,24" />
                              </svg>
                            </div>
                          </a>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Courses</h4>
                  </div>
                  <ul className="service__details__1__cat">
                    {renderServices()}
                  </ul>
                </div>

                <div
                  className="sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  <h3>Contact Us For Any Require</h3>
                  <div className="services__icon">
                    <i className="icon-19"></i>
                  </div>
                  <p>Need help? Call us:</p>
                  <h4>+65 90059743</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </>
  );
};

export default RpaTraining;
