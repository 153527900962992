import React, { useState } from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link, useLocation } from "react-router-dom";
import two from "../../assets/images/resource/choose-01.png";
import service from "../../assets/images/resource/data science.jpg";
import basebg from "../../assets/images/resource/base.png";
import g1 from "../../assets/images/resource/data-science.jpg";
import { Helmet } from "react-helmet-async";
import videoSource from "../../assets/videos/DS.mp4";

const Services = () => {
  pageTitle("Data Science");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const handleSubmit = (e) => {
    e.preventDefault();

    // Your logic to handle form submission goes here
    console.log("Form submitted:", formData);

    // Clear the form fields after submission if needed
    setFormData({
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    });
  };

  const servicesData = [
    { id: 1, title: "RPA & AI", slug: "/RPA-AI" },
    { id: 2, title: "Whatsapp Services", slug: "/Whatsapp-estore" },
    { id: 3, title: "Web Development", slug: "/Web-Development" },
    { id: 4, title: "Digital Consulting", slug: "/digital-consultancy" },
    { id: 5, title: "Data Engineering", slug: "/Data-Engineering" },
    { id: 6, title: "Data Science", slug: "/Data-Science" },
  ];

  const renderServices = () => {
    return servicesData.map((service) => (
      <li
        key={service.id}
        className={service.slug === "/Data-Science" ? "active" : ""}
      >
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <Helmet>
        {/* SEO Meta Tags */}
        <title>Data Science Services | Unlocking Insights</title>
        <meta
          name="description"
          content="Explore our Data Science services offering advanced strategies, application prototypes, and development services to unlock insights for your business."
        />
        <meta
          name="keywords"
          content="Data Science, Data Engineering, Machine Learning, AI, Business Intelligence, Cloud Computing, Data Analysis"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://siw.sg/data-science" />
      </Helmet>
      <HeaderOne></HeaderOne>
      {/* <BreadCrumb></BreadCrumb> */}
      <section className="page__title p_relative">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${g1})` }}
        ></div>
        <div className="auto-container">
          <div className="content__box p_relative">
            {/* <ul className="bread__crumb">
                    <li><Link to="/">Home</Link></li>
                    {pathnames.map((name, index) => (
                    <li key={index}>
                        <span> -</span>
                        <Link to={`/${pathnames.slice(0, index + 1).join('/')}`}>{name}</Link>
                    </li>
                    ))}
                </ul> */}
            {/* <h1 className="title">{pathnames.length > 0 ? pathnames[pathnames.length - 1] : 'Home'}</h1> */}
          </div>
        </div>
      </section>
      <div className="service__details p_relative ">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="" />
                </figure>
                <h2>
                  Unlocking Insights Through Advanced <span>Data Science.</span>{" "}
                </h2>
                <h3>
                  <span>Strategic advising services</span>{" "}
                </h3>
                <p>
                  We tailor our collaboration to your unique business goals. The
                  output of our strategic process highlights:
                </p>
                <ul>
                  <li>1. The data opportunities and how to prioritize them</li>
                  <li>
                    {" "}
                    2. The organizational change required to effectively execute
                    across people, process, and org structure
                  </li>
                  <li> 3. The technologies investments you should make</li>
                </ul>
                <h3>
                  <span>Application prototype and development services</span>{" "}
                </h3>
                <p>
                  {" "}
                  We partner with you for a three-phase engagement to bring your
                  project from science to engineering:
                </p>
                <ul>
                  <li>1. Exploration (two weeks)</li>
                  <li>
                    {" "}
                    2. Algorithmic excellence (a few weeks to a couple of
                    months)
                  </li>
                  <li>
                    {" "}
                    3. Operationalization (a few weeks to a couple of months)
                  </li>
                </ul>

                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/blog-HR" target="_blank">
                        <i className="icon-04"></i> Human Resources
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/Web-Development" target="_blank">
                        <i className="icon-04"></i> Web Development
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/blog-Content-Writing" target="_blank">
                        <i className="icon-04"></i> Content Writing{" "}
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/blog-IT" target="_blank">
                        <i className="icon-04"></i> IT Support
                      </Link>
                    </li>
                  </div>
                </div>
                <p>
                  We empower businesses to own their future by addressing
                  capability gaps in machine learning from a technical, process,
                  people, and organizational perspective. Our data science
                  services guide organizations through the entire project
                  lifecycle, including planning, feasibility assessments,
                  prototyping, and deployment. By providing practical support at
                  each stage, we instill confidence in both the technology and
                  the processes involved, enabling teams to independently
                  execute future projects with assurance.
                </p>
                <p>
                  In today's competitive landscape, companies that effectively
                  apply machine learning can drive efficiency through automation
                  and create new business opportunities. Our data science
                  solutions enable businesses to better understand customer
                  behaviors, allowing them to reduce churn and enhance customer
                  satisfaction. Additionally, organizations can more precisely
                  spot anomalies in their data, which is crucial for identifying
                  and mitigating cyber threats before they escalate.
                </p>
                <p>
                  Furthermore, leveraging data science empowers businesses to
                  make informed and confident product and business decisions. By
                  analyzing trends and patterns in data, companies can innovate
                  their offerings and respond proactively to market changes. Our
                  expert team ensures that organizations harness the full
                  potential of their data, transforming it into actionable
                  insights that drive growth.
                </p>
                {""}
                <p>
                  With our support, you can unlock the true value of your data
                  and embark on a transformative journey that positions your
                  business for long- term success. By investing in our data
                  science services, you're not just adopting a technology;
                  you're committing to a strategy that will empower your
                  organization to thrive in the future.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div
                  className="sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  {/* <h3>WhatsApp Services</h3> */}
                  <video
                    controls
                    style={{ width: "100%", marginRight: "10px" }}
                  >
                    <source src={videoSource} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Services</h4>
                  </div>

                  <ul className="service__details__1__cat">
                    {renderServices()}
                  </ul>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      {/* <div className="service__contact see__pad p_relative">
        <div className="auto-container">
          <div className="service__contact__block">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="service__form__data">
                  <div className="form__title">
                    <div className="title__data">
                      <div className="sub__title">
                        <h4>Contact Us</h4>
                      </div>
                      <div className="title">
                        <h2>Send message</h2>
                      </div>
                    </div>
                  </div>
                  <div className="form-inner">
                    <form
                      onSubmit={handleSubmit}
                      method="post"
                      action="contact.php"
                      className="default-form"
                    >
                      <div className="row clearfix">
                        <div className="col-xl-6 form-group">
                          <input
                            type="name"
                            name="name"
                            placeholder="Your Name *"
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Your Email *"
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="text"
                            name="subject"
                            placeholder="Your Subject "
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Your Phone "
                            required=""
                          />
                        </div>
                        <div className="ccol-xl-6 col-lg-12 form-group">
                          <textarea
                            name="message"
                            placeholder="Message"
                          ></textarea>
                        </div>
                        <div className="col-xl-12 form-group message-btn btn-box">
                          <button
                            className="theme-btn theme-btn-one"
                            type="submit"
                            name="submit-form"
                          >
                            Send your Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 p_relative">
                <div className="service__contact__img">
                  <figure>
                    <img src={two} alt=" /" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <FooterOne></FooterOne>
    </>
  );
};

export default Services;
