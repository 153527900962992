import React, { useState } from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link, useLocation } from "react-router-dom";
import two from "../../assets/images/resource/choose-01.png";
import service from "../../assets/images/resource/Data eng.jpg";
import basebg from "../../assets/images/resource/base.png";
import g1 from "../../assets/images/resource/data-engi.png";
import { Helmet } from "react-helmet-async";
import videoSource from "../../assets/videos/DE.mp4";

const DigitalMarketing = () => {
  pageTitle("Data Engineering");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const handleSubmit = (e) => {
    e.preventDefault();

    // Your logic to handle form submission goes here
    console.log("Form submitted:", formData);

    // Clear the form fields after submission if needed
    setFormData({
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    });
  };

  const servicesData = [
    { id: 1, title: "RPA & AI", slug: "/RPA-AI" },
    { id: 2, title: "Whatsapp Services", slug: "/Whatsapp-estore" },
    { id: 3, title: "Web Development", slug: "/Web-Development" },
    { id: 4, title: "Digital Consulting", slug: "/digital-consultancy" },
    { id: 5, title: "Data Engineering", slug: "/Data-Engineering" },
    { id: 6, title: "Data Science", slug: "/Data-Science" },
  ];

  const renderServices = () => {
    return servicesData.map((service) => (
      <li
        key={service.id}
        className={service.slug === "/Web-Development" ? "active" : ""}
      >
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <Helmet>
        <title>Web Development | Digital Marketing Services</title>

        <meta
          name="description"
          content="Build a robust online presence with our expert web development services. We specialize in e-commerce, responsive design, and web apps."
        />

        <meta
          name="keywords"
          content="web development, e-commerce development, responsive websites, web app development, SEO, digital marketing"
        />

        <meta
          property="og:title"
          content="Web Development | Digital Marketing Services"
        />

        <meta
          property="og:description"
          content="Create a user-friendly and SEO-optimized website for your business."
        />

        <meta property="og:image" content={service} />
      </Helmet>
      <HeaderOne></HeaderOne>
      {/* <BreadCrumb></BreadCrumb> */}
      <section className="page__title p_relative">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${g1})` }}
        ></div>
        <div className="auto-container">
          <div className="content__box p_relative">
            {/* <ul className="bread__crumb">
                    <li><Link to="/">Home</Link></li>
                    {pathnames.map((name, index) => (
                    <li key={index}>
                        <span> -</span>
                        <Link to={`/${pathnames.slice(0, index + 1).join('/')}`}>{name}</Link>
                    </li>
                    ))}
                </ul> */}
            {/* <h1 className="title">{pathnames.length > 0 ? pathnames[pathnames.length - 1] : 'Home'}</h1> */}
          </div>
        </div>
      </section>
      <div className="service__details p_relative ">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="" />
                </figure>
                <h2>
                  Empowering Data-Driven Decisions with{" "}
                  <span>Advanced Engineering .</span>{" "}
                </h2>
                <p>
                  Throughout your data-driven journey, use cases, and with that
                  the architecture and deployment to support them, change.
                  SmartServices provide you the right offerings and support for
                  each stage on the journey; from launching to accelerating and
                  expanding your deployment. We partner with you to provide
                  hands-on technical insight and advice, and let you benefit
                  from our widest real-world experience and most capable
                  technical experts.
                </p>
                <h3>
                  <span>SiW Start</span>{" "}
                </h3>
                <ul>
                  <li>
                    1. Get started with the Cloudera Platform in your data
                    center
                  </li>
                  <li>
                    {" "}
                    2. Design and setup a Cloudera cluster, based on best
                    practices
                  </li>
                  <li>
                    {" "}
                    3. Enhanced security for authentication, authorization,
                    auditing and data governance
                  </li>
                  <li>
                    4. Implement an Edge2Al use case that demonstrates value.
                  </li>
                </ul>
                <h3>
                  <span>SiW Migrate</span>{" "}
                </h3>
                <ul>
                  <li>
                    1. Assessment and planning to optimize your current state to
                    hybrid architecture
                  </li>
                  <li>
                    {" "}
                    2. Upgrade legacy Cloudera CDH or HDP deployments to the
                    latest versions
                  </li>
                  <li>
                    {" "}
                    3.Migrate legacy Cloudera CDH or HDP workloads to CDP in
                    your data center
                  </li>
                </ul>
                <h3>
                  <span>SiW Cloud</span>{" "}
                </h3>
                <ul>
                  <li>
                    1. Get started on your cloud analytics journey with CDP on
                    Public Cloud
                  </li>
                  <li>
                    {" "}
                    2. Supporting shift-to-cloud, and burst- to-cloud adoption
                    scenarios
                  </li>
                  <li>
                    {" "}
                    3. Right level of assistance required : onboarding and
                    planning, launching or accelerating
                  </li>
                </ul>
                <h3>
                  <span>SiW Upgrade</span>{" "}
                </h3>
                <ul>
                  <li>
                    1. Comprehensive platform health check and use case analysis
                    for optimizations
                  </li>
                  <li>
                    {" "}
                    2. In-place upgrade migration plan based on requirements and
                    best practices
                  </li>
                  <li>
                    {" "}
                    3. Upgrade existing Cloudera CDH or HDP deployment to CDP
                    with minimum disruption
                  </li>
                </ul>
                <h3>
                  <span>SiW Motion</span>{" "}
                </h3>
                <ul>
                  <li>
                    1. Get started with real-time analytics using Cloudera Data
                    Flow (CDF)
                  </li>
                  <li> 2. Data In Motion stack design and setup</li>
                  <li> 3.Streaming analytics use case implementation</li>
                </ul>
                <h3>
                  <span>SiW Health</span>{" "}
                </h3>
                <ul>
                  <li>
                    1. Comprehensive platform health check for optimal
                    performance overall
                  </li>
                  <li>
                    {" "}
                    2. Review cluster and components, data and applications,
                    security and governance as well as cluster operations
                  </li>
                  <li>
                    {" "}
                    3. Application of prioritized recommendations for
                    optimization
                  </li>
                </ul>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/blog-dataPipeline" target="_blank">
                        <i className="icon-04"></i>Data Pipeline Automation
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/Big-Data-Pipeline" target="_blank">
                        <i className="icon-04"></i>Scalable Data Architecture
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/blog-Data-Quality" target="_blank">
                        <i className="icon-04"></i> Data Quality Management{" "}
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/blog-Analytics-Tool" target="_blank">
                        <i className="icon-04"></i> Integration with Analytics
                        Tools
                      </Link>
                    </li>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div
                  className="sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  {/* <h3>WhatsApp Services</h3> */}
                  <video
                    controls
                    style={{ width: "100%", marginRight: "10px" }}
                  >
                    <source src={videoSource} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Services</h4>
                  </div>

                  <ul className="service__details__1__cat">
                    {renderServices()}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterOne></FooterOne>
    </>
  );
};

export default DigitalMarketing;
