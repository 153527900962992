import React, { useState } from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link, useLocation } from "react-router-dom";
import two from "../../assets/images/resource/choose-01.png";
import service from "../../assets/images/resource/siw-Business-Intelligence-serv.jpg";
import basebg from "../../assets/images/resource/base.png";
import g1 from "../../assets/images/resource/Business-intelligence.png";
import { Helmet } from "react-helmet-async";

const BusinessIntelligence = () => {
  pageTitle("Business Intelligence");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your logic to handle form submission goes here
    console.log("Form submitted:", formData);

    // Clear the form fields after submission if needed
    setFormData({
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    });
  };

  const servicesData = [
    { id: 1, title: "RPA & AI", slug: "/RPA-AI" },
    { id: 2, title: "Whatsapp Services", slug: "/Whatsapp-estore" },
    { id: 3, title: "Web Development", slug: "/Web-Development" },
    { id: 4, title: "Digital Consulting", slug: "/digital-consultancy" },
    { id: 5, title: "Data Engineering", slug: "/Data-Engineering" },
    { id: 6, title: "Data Science", slug: "/Data-Science" },
  ];
  const renderServices = () => {
    return servicesData.map((service) => (
      <li
        key={service.id}
        className={service.slug === "/digital-marketing" ? "active" : ""}
      >
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <Helmet>
        <title>Business Intelligence - Empower Smarter Decisions | SIW</title>
        <meta
          name="description"
          content="Discover how Business Intelligence solutions from SIW empower smarter decisions with advanced analytics, data visualization, and actionable insights."
        />
        <meta
          name="keywords"
          content="Business Intelligence, data analytics, data visualization, advanced analytics, SIW"
        />
        <meta
          property="og:title"
          content="Business Intelligence - Empower Smarter Decisions | SIW"
        />
        <meta
          property="og:description"
          content="Learn how SIW's Business Intelligence services help organizations make data-driven decisions and optimize performance with advanced tools."
        />
        <meta property="og:image" content={service} />
        <meta
          property="og:url"
          content="https://siw.sg/Business-Intelligence"
        />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://siw.sg/Business-Intelligence" />
      </Helmet>

      <HeaderOne></HeaderOne>
      {/* <BreadCrumb></BreadCrumb> */}
      <section className="page__title p_relative">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${g1})` }}
        ></div>
        <div className="auto-container">
          <div className="content__box p_relative">
            {/* <ul className="bread__crumb">
                    <li><Link to="/">Home</Link></li>
                    {pathnames.map((name, index) => (
                    <li key={index}>
                        <span> -</span>
                        <Link to={`/${pathnames.slice(0, index + 1).join('/')}`}>{name}</Link>
                    </li>
                    ))}
                </ul> */}
            {/* <h1 className="title">{pathnames.length > 0 ? pathnames[pathnames.length - 1] : 'Home'}</h1> */}
          </div>
        </div>
      </section>
      <div className="service__details p_relative ">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="" />
                </figure>
                <h2>
                  Empowering Smarter Decisions with Advanced{" "}
                  <span>Business Intelligence.</span>{" "}
                </h2>
                <h3>
                  <span>Business Intelligence</span>{" "}
                </h3>
                <p>
                  Business intelligence (BI) combines business analytics, data
                  mining, data visualization, data tools and infrastructure, and
                  best practices to help organizations to make more data-driven
                  decisions. In practice, you know you've got modern business
                  intelligence when you have a comprehensive view of your
                  organization's data and use that data to drive change,
                  eliminate inefficiencies, and quickly adapt to market or
                  supply changes.
                </p>{" "}
                <p>
                  {" "}
                  Much more than a specific "thing," business intelligence is
                  rather an umbrella term that covers the processes and methods
                  of collecting, storing, and analyzing data from business
                  operations or activities to optimize performance. All of these
                  things come together to create a comprehensive view of a
                  business to help people make better, actionable decisions.
                  Over the past few years, business intelligence has evolved to
                  include more processes and activities to help improve
                  performance.
                </p>
                <ul>
                  <li>
                    <span>Data mining:</span> Using databases, statistics and
                    machine learning to uncover trends in large datasets.
                  </li>
                  <li>
                    <span>Reporting:</span> Sharing data analysis to
                    stakeholders so they can draw conclusions and make
                    decisions.
                  </li>
                  <li>
                    <span>Performance metrics and benchmarking:</span> Comparing
                    current performance data to historical data to track
                    performance against goals, typically using customized
                    dashboards.
                  </li>
                  <li>
                    <span>Descriptive analytics: </span>Using preliminary data
                    analysis to find out what happened.
                  </li>
                  <li>
                    <span>Querying: </span>Asking the data specific questions,
                    BI pulling the answers from the datasets.
                  </li>
                  <li>
                    <span>Statistical analysis:</span> Taking the results from
                    descriptive analytics and further exploring the data using
                    statistics such as how this trend happened and why.
                  </li>
                  <li>
                    <span>Data visualization:</span> Turning data analysis into
                    visual representations such as charts, graphs, and
                    histograms to more easily consume data.
                  </li>
                  <li>
                    <span>Visual analysis:</span> Exploring data through visual
                    storytelling to communicate insights on the fly and stay in
                    the flow of analysis.
                  </li>
                  <li>
                    <span>Data preparation:</span> Compiling multiple data
                    sources, identifying the dimensions and measurements,
                    preparing it for data analysis.
                  </li>
                </ul>
                <h3>
                  <span>Why is business intelligence important?</span>
                </h3>
                <p>
                  Business intelligence can help companies make better decisions
                  by showing present and historical data within their business
                  context. Analysts can leverage BI to provide performance and
                  competitor benchmarks to make the organization run smoother
                  and more efficiently.
                </p>
                <p></p>
                <h3>
                  <span>
                    A few ways that business intelligence can help companies
                    make smarter, data-driven decisions:
                  </span>
                </h3>
                <ul>
                  <li>1. Identify ways to increase profit</li>
                  <li>2. Analyze customer behavior</li>
                  <li>3. Compare data with competitors</li>
                  <li>4. Track performance</li>
                  <li>5. Optimize operations</li>
                  <li>6. Predict success</li>
                  <li>7. Spot market trends</li>
                  <li>8. Discover issues or problems</li>
                </ul>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Data Integration and
                        Warehousing
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="https://ai.siw.sg/">
                        <i className="icon-04"></i> Advanced Analytics and
                        Reporting
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i>Predictive Analytics and
                        Forecasting{" "}
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="#">
                        <i className="icon-04"></i> Self-Service Analytics
                      </Link>
                    </li>
                  </div>
                </div>
                <h3>
                  <span>How business intelligence works</span>
                </h3>
                <p>
                  Businesses and organizations have questions and goals. To
                  answer these questions and track performance against these
                  goals, they gather the necessary data, analyze it, and
                  determine which actions to take to reach their goals. Many
                  disparate industries have adopted BI ahead of the curve,
                  including healthcare, information technology, and education.
                  All organizations can use data to transform operations.
                </p>
                <p>
                  <h3>
                    <span>The future role of business intelligence</span>
                  </h3>
                  Business intelligence is continually evolving according to
                  business needs and technology, so each year, we identify
                  current trends to keep users up-to-date on innovations.
                  Realize that artificial intelligence and machine learning will
                  continue to grow, and businesses can integrate the insights
                  from Al into a broader BI strategy. As companies strive to be
                  more data-driven, efforts to share data, and collaborate will
                  increase. Data visualization will be even more essential to
                  work together across teams and departments. Bl offers
                  capabilities for near real-time sales tracking, allows users
                  to discover insights into customer behavior, forecast profits,
                  and more.
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Services</h4>
                  </div>
                  <ul className="service__details__1__cat">
                    {renderServices()}
                  </ul>
                </div>
                <div
                  className=" sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  <h3>Contact Us For Any Require</h3>
                  <div className="services__icon">
                    <i className="icon-19"></i>
                  </div>
                  <p>Need help? Call us:</p>
                  <h4>+65 90059743</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="service__contact see__pad p_relative">
        <div className="auto-container">
          <div className="service__contact__block">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="service__form__data">
                  <div className="form__title">
                    <div className="title__data">
                      <div className="sub__title">
                        <h4>Contact Us</h4>
                      </div>
                      <div className="title">
                        <h2>Send message</h2>
                      </div>
                    </div>
                  </div>
                  <div className="form-inner">
                    <form
                      onSubmit={handleSubmit}
                      method="post"
                      action="contact.php"
                      className="default-form"
                    >
                      <div className="row clearfix">
                        <div className="col-xl-6 form-group">
                          <input
                            type="name"
                            name="name"
                            placeholder="Your Name *"
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Your Email *"
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="text"
                            name="subject"
                            placeholder="Your Subject "
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Your Phone "
                            required=""
                          />
                        </div>
                        <div className="ccol-xl-6 col-lg-12 form-group">
                          <textarea
                            name="message"
                            placeholder="Message"
                          ></textarea>
                        </div>
                        <div className="col-xl-12 form-group message-btn btn-box">
                          <button
                            className="theme-btn theme-btn-one"
                            type="submit"
                            name="submit-form"
                          >
                            Send your Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 p_relative">
                <div className="service__contact__img">
                  <figure>
                    <img src={two} alt=" /" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <FooterOne></FooterOne>
    </>
  );
};

export default BusinessIntelligence;
