import React, { useState } from "react";

import { pageTitle } from "../PageTitle";

import BreadCrumb from "../BreadCrumb";

import HeaderOne from "../Header/HeaderOne";

import FooterOne from "../Footer/FooterOne";

import { Link, useLocation } from "react-router-dom";

import two from "../../assets/images/resource/choose-01.png";

import service from "../../assets/images/resource/RPA--.jpg";

import basebg from "../../assets/images/resource/base.png";

import g1 from "../../assets/images/resource/RPA-b.png";
import videoSource from "../../assets/videos/vdo-1.mp4";

const DigitalMarketing = () => {
  pageTitle("RPA in finance");

  const [formData, setFormData] = useState({
    name: "",

    email: "",

    subject: "",

    phone: "",

    message: "",
  });

  const location = useLocation();

  const pathnames = location.pathname.split("/").filter((x) => x);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your logic to handle form submission goes here

    console.log("Form submitted:", formData);

    // Clear the form fields after submission if needed

    setFormData({
      name: "",

      email: "",

      subject: "",

      phone: "",

      message: "",
    });
  };

  const servicesData = [
    { id: 1, title: "RPA & AI", slug: "/RPA-AI" },
    { id: 2, title: "Whatsapp Services", slug: "/Whatsapp-estore" },
    { id: 3, title: "Web Development", slug: "/Web-Development" },
    { id: 4, title: "Digital Consulting", slug: "/digital-consultancy" },
    { id: 5, title: "Data Engineering", slug: "/Data-Engineering" },
    { id: 6, title: "Data Science", slug: "/Data-Science" },
  ];

  const renderServices = () => {
    return servicesData.map((service) => (
      <li
        key={service.id}
        className={service.slug === "/content-marketing" ? "active" : ""}
      >
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <HeaderOne></HeaderOne>

      {/* <BreadCrumb></BreadCrumb> */}

      <section className="page__title p_relative">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${g1})` }}
        ></div>

        <div className="auto-container">
          <div className="content__box p_relative">
            {/* <ul className="bread__crumb">

                    <li><Link to="/">Home</Link></li>

                    {pathnames.map((name, index) => (

                    <li key={index}>

                        <span> -</span>

                        <Link to={`/${pathnames.slice(0, index + 1).join('/')}`}>{name}</Link>

                    </li>

                    ))}

                </ul> */}

            {/* <h1 className="title">{pathnames.length > 0 ? pathnames[pathnames.length - 1] : 'Home'}</h1> */}
          </div>
        </div>
      </section>

      <div className="service__details p_relative ">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="" />
                </figure>

                <h2>Robotic Process Automation (RPA). </h2>

                <p>
                  Automation provides far more than a boost in operational
                  efficiency for businesses. In addition to driving higher
                  revenue and enhancing customer experience, over 90% of
                  organizations recognize automation as a catalyst for their
                  digital transformation journey.
                </p>

                <h3>
                  <span>What is Robotic Process Automation (RPA)?</span>{" "}
                </h3>

                <p>
                  Robotic Process Automation (RPA) is a technology that
                  automates business processes by replicating how a human would
                  perform repetitive tasks and make decisions based on
                  established rules. RPA bots—essentially software scripts—can
                  manage, execute, and monitor repetitive tasks that do not
                  require human judgment, freeing employees to focus on more
                  complex, high-value work.
                </p>

                <h3>
                  <span>How fast are RPA bots?</span>{" "}
                </h3>

                <p>
                  RPA bots operate at speeds far greater than humans and can
                  work continuously—24/7, without breaks or downtime—resulting
                  in a 12x productivity boost.
                </p>

                <h3>
                  <span>
                    Non-Invasive and Compatible with Existing Software
                  </span>{" "}
                </h3>

                <p>
                  RPA is designed to work with existing applications through the
                  user interface, just as a person would, requiring no changes
                  to the software or systems already in place. This is a
                  distinct advantage over traditional automation.
                </p>

                <h3>
                  <span>Intelligent Automation with AI Integration</span>{" "}
                </h3>

                <p>
                  Advancements in Artificial Intelligence (AI) have extended the
                  scope of RPA beyond rule-based tasks. By incorporating AI, RPA
                  can now handle cognitive, decision-based processes, creating
                  what we call Intelligent Automation.
                </p>

                <h3>
                  <span>Key Benefits of RPA for Your Business</span>{" "}
                </h3>

                <ul>
                  <li>
                    <strong>Increased productivity</strong>: Bots work around
                    the clock, completing repetitive tasks quickly and
                    error-free.
                  </li>

                  <li>
                    <strong>Reduced compliance costs and risks</strong>:
                    Consistent execution by bots reduces human error and
                    compliance risk.
                  </li>

                  <li>
                    <strong>Enhanced employee engagement</strong>: RPA relieves
                    employees of mundane tasks, allowing them to engage in more
                    creative and interactive work.
                  </li>

                  <li>
                    <strong>Improved customer service</strong>: Higher
                    productivity and employee engagement contribute to better
                    customer experiences.
                  </li>

                  <li>
                    <strong>Revenue growth</strong>: Freed-up capacity lets
                    businesses explore new growth opportunities and revenue
                    streams.
                  </li>

                  <li>
                    <strong>Increased resilience</strong>: By reducing reliance
                    on human resources, RPA strengthens business continuity,
                    especially during crises like the Covid-19 pandemic.
                  </li>
                </ul>

                <h3>
                  <span>Which Processes are Ideal for RPA?</span>{" "}
                </h3>

                <p>
                  Selecting the right processes to automate is essential for
                  maximizing ROI on automation efforts. Processes that are best
                  suited for RPA generally share the following characteristics:
                </p>

                <ul>
                  <li>
                    <strong>1. Rule-Based</strong>: Can the process be outlined
                    in a flowchart? Specifically, does it require human judgment
                    or discretion? Processes that can be fully mapped out with
                    clear rules are ideal.
                  </li>

                  <li>
                    <strong>2. High Transaction Volumes</strong>: How much time
                    is spent daily on this task? Generally, processes that
                    consume more time offer higher potential for ROI through
                    automation.
                  </li>

                  <li>
                    <strong>3. Low Exceptions</strong>: Are there edge cases
                    that a bot would need to handle? Ideally, the fewer
                    exceptions or error-handling scenarios, the more suitable
                    the process.
                  </li>

                  <li>
                    <strong>4. Stable and Well-Defined Processes</strong>: Is
                    there an up-to-date Standard Operating Procedure (SOP)?
                    Processes with minimal changes to their SOPs in the past or
                    upcoming year are more compatible with automation.
                  </li>

                  <li>
                    <strong>5. Low System Change</strong>: Are there any planned
                    upgrades, replacements, or enhancements to systems involved
                    in this process over the next year? Processes on stable
                    systems with minimal expected changes are more suited to
                    automation.
                  </li>

                  <li>
                    <strong>
                      6. Structured Data and Readable Digital Inputs
                    </strong>
                    : Is the input and output data structured and in a
                    consistent digital format? Having data readily accessible in
                    a digital format is crucial for effective training and
                    testing of RPA bots.
                  </li>
                </ul>

                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/Rpa-finance" target="_blank">
                        <i className="icon-04"></i>RPA in Finance
                      </Link>
                    </li>
                  </div>

                  {/* <div className="col-lg-6 col-md-12">

                    <li>

                      {" "}

                      <Link to="/">

                        <i className="icon-04"></i>HR

                      </Link>

                    </li>

                  </div>  */}
                </div>
              </div>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div
                  className="sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  <h3>RPA</h3>
                  <video
                    controls
                    style={{ width: "100%", marginRight: "10px" }}
                  >
                    <source src={videoSource} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Services</h4>
                  </div>

                  <ul className="service__details__1__cat">
                    {renderServices()}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="service__contact see__pad p_relative">

        <div className="auto-container">

          <div className="service__contact__block">

            <div className="row">

              <div className="col-lg-6 col-md-12">

                <div className="service__form__data">

                  <div className="form__title">

                    <div className="title__data">

                      <div className="sub__title">

                        <h4>Contact Us</h4>

                      </div>

                      <div className="title">

                        <h2>Send message</h2>

                      </div>

                    </div>

                  </div>

                  <div className="form-inner">

                    <form

                      onSubmit={handleSubmit}

                      method="post"

                      action="contact.php"

                      className="default-form"

                    >

                      <div className="row clearfix">

                        <div className="col-xl-6 form-group">

                          <input

                            type="name"

                            name="name"

                            placeholder="Your Name *"

                            required=""

                          />

                        </div>

                        <div className="col-xl-6 col-lg-12 form-group">

                          <input

                            type="email"

                            name="email"

                            placeholder="Your Email *"

                            required=""

                          />

                        </div>

                        <div className="col-xl-6 col-lg-12 form-group">

                          <input

                            type="text"

                            name="subject"

                            placeholder="Your Subject "

                            required=""

                          />

                        </div>

                        <div className="col-xl-6 col-lg-12 form-group">

                          <input

                            type="text"

                            name="phone"

                            placeholder="Your Phone "

                            required=""

                          />

                        </div>

                        <div className="ccol-xl-6 col-lg-12 form-group">

                          <textarea

                            name="message"

                            placeholder="Message"

                          ></textarea>

                        </div>

                        <div className="col-xl-12 form-group message-btn btn-box">

                          <button

                            className="theme-btn theme-btn-one"

                            type="submit"

                            name="submit-form"

                          >

                            Send your Message

                          </button>

                        </div>

                      </div>

                    </form>

                  </div>

                </div>

              </div>

              <div className="col-lg-6 col-md-12 p_relative">

                <div className="service__contact__img">

                  <figure>

                    <img src={two} alt=" /" />

                  </figure>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div> */}

      <FooterOne></FooterOne>
    </>
  );
};

export default DigitalMarketing;
