import React, { useState } from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link, useLocation } from "react-router-dom";
import two from "../../assets/images/resource/choose-01.png";
import service from "../../assets/images/resource/Wp.jpg";
import basebg from "../../assets/images/resource/base.png";
import g1 from "../../assets/images/resource/2.png";
import { Helmet } from "react-helmet-async";
import videoSource from "../../assets/videos/vdo-6.mp4";

const Whatsapp = () => {
  pageTitle("Whatsapp E-store");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const handleSubmit = (e) => {
    e.preventDefault();

    // Your logic to handle form submission goes here
    console.log("Form submitted:", formData);

    // Clear the form fields after submission if needed
    setFormData({
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    });
  };

  const servicesData = [
    { id: 1, title: "RPA & AI", slug: "/RPA-AI" },
    { id: 2, title: "Whatsapp Services", slug: "/Whatsapp-estore" },
    { id: 3, title: "Web Development", slug: "/Web-Development" },
    { id: 4, title: "Digital Consulting", slug: "/digital-consultancy" },
    { id: 5, title: "Data Engineering", slug: "/Data-Engineering" },
    { id: 6, title: "Data Science", slug: "/Data-Science" },
  ];

  const renderServices = () => {
    return servicesData.map((service) => (
      <li
        key={service.id}
        className={service.slug === "/Whatsapp-estore" ? "active" : ""}
      >
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <Helmet>
        <title>WhatsApp Estore Services | Transform Your Business</title>
        <meta
          name="description"
          content="Transform your business by leveraging WhatsApp for your E-store, enhancing customer engagement, and driving sales."
        />
        <meta
          name="keywords"
          content="WhatsApp, E-store, Customer Support, Sales, Product Catalog"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://siw.sg/Whatsapp-estore" />
      </Helmet>

      <HeaderOne></HeaderOne>
      {/* <BreadCrumb></BreadCrumb> */}
      <section className="page__title p_relative">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${g1})` }}
        ></div>
        <div className="auto-container">
          <div className="content__box p_relative">
            {/* <ul className="bread__crumb">
                    <li><Link to="/">Home</Link></li>
                    {pathnames.map((name, index) => (
                    <li key={index}>
                        <span> -</span>
                        <Link to={`/${pathnames.slice(0, index + 1).join('/')}`}>{name}</Link>
                    </li>
                    ))}
                </ul> */}
            {/* <h1 className="title">{pathnames.length > 0 ? pathnames[pathnames.length - 1] : 'Home'}</h1> */}
          </div>
        </div>
      </section>
      <div className="service__details p_relative ">
        <div className="auto-container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="" />
                </figure>
                <h2>
                  <span>WhatsApp Services Overview</span>{" "}
                </h2>
                <ul>
                  <li>
                    <strong>Automation:</strong> Enables chatbots and
                    pre-configured workflows to handle customer queries
                    efficiently.
                  </li>
                  <li>
                    <strong>Template Messaging:</strong> Allows sending
                    structured and compliant messages for promotions or
                    transactions.
                  </li>
                  <li>
                    <strong>CRM Integration:</strong> Seamlessly connects with
                    tools like Shopify, HubSpot, and Zoho for better lead and
                    customer management.
                  </li>
                  <li>
                    <strong>Analytics:</strong> Provides insights into campaign
                    performance, operator efficiency, and customer interactions.
                  </li>
                  <li>
                    <strong>e-Commerce Support:</strong> Features like product
                    catalogs, auto-checkout, and payment integration cater to
                    online sales.
                  </li>
                  <li>
                    <strong>Mobile Accessibility:</strong> A dedicated app
                    ensures businesses can manage operations on the go.
                  </li>
                </ul>
                <h3>
                  <span>WhatsApp Store</span>{" "}
                </h3>
                <p>
                  The WhatsApp Store allows businesses to showcase their
                  products in a catalog, enabling customers to browse and order
                  directly through WhatsApp. This feature integrates seamlessly
                  into the chat interface, offering an intuitive shopping
                  experience without requiring external links or apps.
                </p>
                <h4 style={{ marginTop: "10px" }}>
                  <span>Payment Automation Flow</span>{" "}
                </h4>
                <ul>
                  <li>
                    <strong>Order Placement:</strong> Customers can browse a
                    catalog embedded in the chat and select items they wish to
                    purchase. Each selection adds items to a virtual cart.
                  </li>
                  <li>
                    <strong>Checkout Process:</strong> Customers confirm their
                    order directly in the chat interface. Payment details and
                    options, such as card payments or integration with
                    third-party platforms (e.g., Razorpay), are presented.
                  </li>
                  <li>
                    <strong>Payment Integration:</strong> Payment gateways
                    handle secure transactions, offering real-time payment
                    updates. Automated confirmation messages are sent to both
                    customers and businesses upon successful payment.
                  </li>
                  <li>
                    <strong>Order Tracking:</strong> Post-payment, the system
                    shares delivery details, including order status and
                    estimated delivery time, enhancing customer communication.
                  </li>
                </ul>

                {/* <ul>
                  <li>1. The data opportunities and how to prioritize them</li>
                  <li>
                    {" "}
                    2. The organizational change required to effectively execute
                    across people, process, and org structure
                  </li>
                  <li> 3. The technologies investments you should make</li>
                </ul> */}
                <h3>
                  <span>WhatsApp e-Commerce</span>{" "}
                </h3>
                <p>
                  Integrating WooCommerce with WhatsApp enhances the e-commerce
                  experience by automating customer interactions. This
                  integration enables businesses to send automated order
                  confirmations, payment updates, and delivery notifications
                  directly through WhatsApp. Additionally, it supports two-way
                  communication, allowing customers to send queries or seek
                  support seamlessly.
                </p>
                <ul>
                  <li>
                    <strong>Automated Order Replies:</strong> Automatically
                    respond to customers with order details such as order ID,
                    payment status, and estimated delivery time after they place
                    an order. This ensures customers stay informed without
                    manual intervention.
                  </li>
                  <li>
                    <strong>Customer Queries Management:</strong> Enable
                    real-time handling of customer inquiries related to
                    products, orders, or delivery through predefined message
                    templates or dynamic responses triggered by keywords.
                  </li>
                  <li>
                    <strong>Customization with Variables:</strong> Use
                    predefined variables to dynamically include
                    customer-specific data in automated messages, such as names,
                    order IDs, or product details, ensuring personalized
                    communication.
                  </li>
                  <li>
                    <strong>Streamlined Communication Flow:</strong> Set up
                    workflows for abandoned cart reminders, order confirmations,
                    or payment follow-ups to boost sales and enhance customer
                    experience.
                  </li>
                  <li>
                    <strong>Enhanced Shopping Experience:</strong> Customers can
                    directly engage via WhatsApp for product inquiries, and
                    businesses can leverage the chatbot for catalog browsing and
                    checkout guidance.
                  </li>
                </ul>

                <h3>
                  <span>WhatsApp API Integration</span>{" "}
                </h3>
                <ul>
                  <li>
                    <strong>Real-Time Data Sharing:</strong> Webhooks enable
                    instant communication between systems by pushing data
                    updates in real-time. For example, when a message template
                    is sent via WhatsApp, webhooks track its delivery, read
                    status, or any errors in execution.
                  </li>
                  <li>
                    <strong>Efficient Workflow Automation:</strong> Actions like
                    order confirmations, status updates, and customer query
                    resolutions are triggered automatically through webhook
                    notifications.
                  </li>
                  <li>
                    <strong>Tracking Template Messages:</strong> Genie uses
                    webhooks to monitor the lifecycle of template messages.
                    Businesses can track delivery statuses, ensuring compliance
                    and optimizing message strategies for higher engagement.
                  </li>
                  <li>
                    <strong>Personalized Updates:</strong> By integrating
                    OpenAI, webhook data can be analyzed to create personalized
                    customer notifications, further enhancing user interaction.
                  </li>
                </ul>

                {/* <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/blog-HR" target="_blank">
                        <i className="icon-04"></i> Human Resources
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/Web-Development" target="_blank">
                        <i className="icon-04"></i> Web Development
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/blog-Content-Writing" target="_blank">
                        <i className="icon-04"></i> Content Writing{" "}
                      </Link>
                    </li>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <li>
                      {" "}
                      <Link to="/blog-IT" target="_blank">
                        <i className="icon-04"></i> IT Support
                      </Link>
                    </li>
                  </div>
                </div> */}

                <p style={{ marginBottom: "30px" }}>
                  Ready to revolutionize your business? Contact us today and let
                  us help you create a WhatsApp Services that drives sales and
                  keeps customers coming back!
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div
                  className="sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  {/* <h3>WhatsApp Services</h3> */}
                  <video
                    controls
                    style={{ width: "100%", marginRight: "10px" }}
                  >
                    <source src={videoSource} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Services</h4>
                  </div>

                  <ul className="service__details__1__cat">
                    {renderServices()}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="service__contact see__pad p_relative">
        <div className="auto-container">
          <div className="service__contact__block">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="service__form__data">
                  <div className="form__title">
                    <div className="title__data">
                      <div className="sub__title">
                        <h4>Contact Us</h4>
                      </div>
                      <div className="title">
                        <h2>Send message</h2>
                      </div>
                    </div>
                  </div>
                  <div className="form-inner">
                    <form
                      onSubmit={handleSubmit}
                      method="post"
                      action="contact.php"
                      className="default-form"
                    >
                      <div className="row clearfix">
                        <div className="col-xl-6 form-group">
                          <input
                            type="name"
                            name="name"
                            placeholder="Your Name *"
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Your Email *"
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="text"
                            name="subject"
                            placeholder="Your Subject "
                            required=""
                          />
                        </div>
                        <div className="col-xl-6 col-lg-12 form-group">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Your Phone "
                            required=""
                          />
                        </div>
                        <div className="ccol-xl-6 col-lg-12 form-group">
                          <textarea
                            name="message"
                            placeholder="Message"
                          ></textarea>
                        </div>
                        <div className="col-xl-12 form-group message-btn btn-box">
                          <button
                            className="theme-btn theme-btn-one"
                            type="submit"
                            name="submit-form"
                          >
                            Send your Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 p_relative">
                <div className="service__contact__img">
                  <figure>
                    <img src={two} alt=" /" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <FooterOne></FooterOne>
    </>
  );
};

export default Whatsapp;
