import React from "react";
import CountUp from "react-countup";
import BannerBg from "../../../assets/images/shapes/shape-02.png";
import AboutUs from "../../../assets/images/resource/about-us.png";
import shape1 from "../../../assets/images/shapes/shape-01.png";

const AboutTwo = () => {
  return (
    <section className="about__section p_relative see__pad">
      <div className="pattern-layer">
        <div
          className="pattern-2 p_absolute"
          data-parallax='{"x": -50}'
          style={{ backgroundImage: `url(${BannerBg})` }}
        ></div>
      </div>
      <div className="boild__text">
        <h1>Smart iLab Works</h1>
      </div>
      <div className="auto-container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="about__block p_relative">
              <figure
                className="wow zoomIn animated"
                data-wow-delay="100ms"
                data-wow-duration="1500ms"
              >
                <img src={AboutUs} alt="" />
              </figure>
              {/* <div
                className="funfact__content about"
                style={{ backgroundImage: `url(${shape1})` }}
              >
                <div className="count-outer count-box">
                  <h1 className="count-text">
                    <CountUp start={0} end={10} />
                    <span>K+</span>
                  </h1>
                </div>
                <p>World wide Client</p>
              </div> */}
            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-5 col-md-12">
            <div className="about__text__block">
              <div className="sub__title">
                <h4>About Company</h4>
              </div>
              <div className="title two">
                <h2 style={{ color: "#009999" }}>
                  Our ultimate goal is to add value to your business.
                </h2>
              </div>
              <div className="texts">
                <p>
                  <strong>At SiW - Smart iLab Works</strong>, we bring over 10
                  years of experience in Singapore’s business landscape,
                  transforming companies with cutting-edge technology to drive
                  efficiency, growth, and innovation.
                </p>
                <p>
                  <br />
                  Based in Singapore, we deliver high-quality, cost-effective,
                  and reliable solutions tailored to evolving business needs.
                </p>
                <p>
                  <br />
                  From business analysis to deployment and maintenance, we
                  provide seamless, end-to-end services, ensuring smooth
                  execution so you can focus on what matters most.
                </p>
                <p>
                  <br />
                  <strong>Our expertise includes:</strong>
                  <ul>
                    <li>✓ RPA and AI to automate processes</li>
                    <li>
                      ✓ WhatsApp E-Store services to enhance customer engagement
                    </li>
                    <li>
                      ✓ Custom Web and Mobile App Development to create powerful
                      digital solutions
                    </li>
                    <li>
                      ✓ Seamless integration of React applications with OpenAPI
                      and Single Sign-On
                    </li>
                    <li>
                      ✓ e-Commerce website and multi-vendor application
                      development
                    </li>
                  </ul>
                </p>
                <p>
                  <br />
                  <strong>We specialize in data engineering</strong> to optimize
                  Hadoop deployments and data science to uncover valuable
                  insights from complex datasets.
                </p>
                <p>
                  <br />
                  <strong>Our digital consulting services</strong> empower
                  businesses with data-driven strategies, market insights, and
                  tailored solutions, strengthening digital presence and
                  ensuring long-term success.
                </p>
                <p>
                  <br />
                  <strong>Through digital consulting</strong>, we drive
                  strategic transformation, optimizing brand positioning,
                  customer engagement, and operational efficiency.
                </p>
                <p>
                  <br />
                  <strong>At SiW</strong>, we don’t just offer solutions—we
                  become your trusted technology partner, delivering innovation
                  with precision. Let’s shape the future together.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutTwo;
