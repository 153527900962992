import React, { useState } from "react";
import { pageTitle } from "../PageTitle";
import BreadCrumb from "../BreadCrumb";
import HeaderOne from "../Header/HeaderOne";
import FooterOne from "../Footer/FooterOne";
import { Link, useLocation } from "react-router-dom";
import two from "../../assets/images/resource/choose-01.png";
import service from "../../assets/images/resource/dc.png";
import basebg from "../../assets/images/resource/base.png";
import g1 from "../../assets/images/resource/dig-cons.jpg";
import { Helmet } from "react-helmet-async";
import videoSource from "../../assets/videos/dig-cons.mp4";

const CloudComputing = () => {
  pageTitle("Cloud computing");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });

  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your logic to handle form submission goes here
    console.log("Form submitted:", formData);

    // Clear the form fields after submission if needed
    setFormData({
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    });
  };

  const servicesData = [
    { id: 1, title: "RPA & AI", slug: "/RPA-AI" },
    { id: 2, title: "Whatsapp Services", slug: "/Whatsapp-estore" },
    { id: 3, title: "Web Development", slug: "/Web-Development" },
    { id: 4, title: "Digital Consulting", slug: "/digital-consultancy" },
    { id: 5, title: "Data Engineering", slug: "/Data-Engineering" },
    { id: 6, title: "Data Science", slug: "/Data-Science" },
  ];
  const renderServices = () => {
    return servicesData.map((service) => (
      <li
        key={service.id}
        className={service.slug === "/digital-marketing" ? "active" : ""}
      >
        <Link to={service.slug}>{service.title}</Link>
      </li>
    ));
  };

  return (
    <>
      <Helmet>
        <title>Digital Consulting | Elevate Your Brand</title>
        <meta
          name="description"
          content="Unlock your brand's potential with our comprehensive Social Media Marketing services, including account setup, creative content, video scheduling, and performance analytics."
        />
        <meta
          name="keywords"
          content="Social Media Marketing, Social Media Management, Creative Content, Animated Videos, Social Media Ads, Performance Analytics"
        />
        <meta
          property="og:title"
          content="Social Media Marketing Solutions | Elevate Your Brand"
        />
        <meta
          property="og:description"
          content="Transform your social presence with our full-suite Social Media Marketing services tailored to drive engagement and growth."
        />
        <meta property="og:image" content={g1} />
        <meta
          property="og:url"
          content="https://siw.sg/social-media-marketing"
        />
        <meta property="og:type" content="website" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://siw.sg/social-media-marketing" />
      </Helmet>
      <HeaderOne></HeaderOne>
      {/* <BreadCrumb></BreadCrumb> */}
      <section className="page__title p_relative">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${g1})` }}
        ></div>
        <div className="auto-container">
          <div className="content__box p_relative">
            {/* <ul className="bread__crumb">
                    <li><Link to="/">Home</Link></li>
                    {pathnames.map((name, index) => (
                    <li key={index}>
                        <span> -</span>
                        <Link to={`/${pathnames.slice(0, index + 1).join('/')}`}>{name}</Link>
                    </li>
                    ))}
                </ul> */}
            {/* <h1 className="title">{pathnames.length > 0 ? pathnames[pathnames.length - 1] : 'Home'}</h1> */}
          </div>
        </div>
      </section>
      <div className="service__details p_relative">
        <div className="auto-container">
          <div className="row">
            {/* Main Content */}
            <div className="col-xl-8 col-lg-12 col-md-12">
              <div className="service__right">
                <figure>
                  <img src={service} alt="" />
                </figure>
                <h2>
                  Digital Consulting Services
                  <span> – Elevate Your Digital Presence</span>
                </h2>

                <div className="service-section">
                  <h3>1. Social Media Account Setup & Management</h3>
                  <p>
                    Starting fresh or need to optimize your existing social
                    media? We create and manage professional business accounts
                    across all major platforms, including:
                  </p>
                  <ul>
                    <li>✔ Facebook</li>
                    <li>✔ Instagram</li>
                    <li>✔ LinkedIn</li>
                    <li>✔ TikTok</li>
                    <li>✔ YouTube & more</li>
                  </ul>
                  <p>
                    We set up your profile with the right branding, business
                    details, and optimized bios. Our team ensures consistent
                    posting, audience engagement, and profile optimization to
                    maintain a strong online presence.
                  </p>
                </div>

                <div className="service-section">
                  <h3>2. Creative Content & Branding</h3>
                  <p>
                    Content is king! We craft engaging and high-quality content
                    tailored to your business goals. Our services include:
                  </p>
                  <ul>
                    <li>✔ Custom graphics & illustrations</li>
                    <li>✔ Reels & carousel posts</li>
                    <li>✔ Infographics & brand storytelling</li>
                    <li>✔ Engaging captions & hashtags</li>
                  </ul>
                  <p>
                    We ensure your brand stands out with unique visuals and
                    strategic content planning.
                  </p>
                </div>

                <div className="service-section">
                  <h3>3. Animated Videos & Social Media Posters</h3>
                  <p>
                    Want to grab attention instantly? Our animated videos and
                    posters make your social media more dynamic.
                  </p>
                  <ul>
                    <li>✔ Explainer videos</li>
                    <li>✔ Short animated ads</li>
                    <li>✔ Promotional reels & motion graphics</li>
                    <li>✔ Festive & campaign-based posters</li>
                  </ul>
                  <p>
                    Animated content boosts engagement, increases brand recall,
                    and simplifies complex ideas.
                  </p>
                </div>

                <div className="service-section">
                  <h3>4. Smart Video Scheduling & Automation</h3>
                  <p>
                    Timing matters in social media! We use advanced metrical
                    tools to schedule and automate your posts/videos at the best
                    times for engagement.
                  </p>
                  <ul>
                    <li>✔ AI-driven post scheduling</li>
                    <li>✔ Best-time analysis for maximum reach</li>
                    <li>✔ Multi-platform scheduling</li>
                  </ul>
                  <p>
                    Our data-backed strategy ensures your content reaches the
                    right audience at the right time.
                  </p>
                </div>

                <div className="service-section">
                  <h3>5. Performance Monitoring & Analytics</h3>
                  <p>
                    We track every post, story, and campaign to analyze
                    performance and optimize future content.
                  </p>
                  <ul>
                    <li>✔ Engagement & reach analysis</li>
                    <li>✔ Follower growth tracking</li>
                    <li>✔ Competitor benchmarking</li>
                    <li>✔ Content improvement strategies</li>
                  </ul>
                  <p>
                    Data-driven insights help us refine your digital strategy
                    for continuous improvement.
                  </p>
                </div>

                <div className="service-section">
                  <h3>6. Social Media Advertising & Paid Campaigns</h3>
                  <p>
                    Want faster results? We design and run targeted ad campaigns
                    to increase brand awareness, drive traffic, and boost
                    conversions.
                  </p>
                  <ul>
                    <li>✔ Facebook & Instagram Ads</li>
                    <li>✔ Google Ads & LinkedIn Ads</li>
                    <li>✔ Lead generation & remarketing</li>
                    <li>✔ Budget-friendly ad strategy</li>
                  </ul>
                  <p>
                    We help you reach the right audience while maximizing your
                    return on investment (ROI).
                  </p>
                </div>

                <div className="service-section">
                  <h3>7. Weekly Reports & Growth Insights</h3>
                  <p>
                    We keep you informed with detailed weekly reports, showing
                    how your brand is growing.
                  </p>
                  <ul>
                    <li>✔ Post & ad performance</li>
                    <li>✔ Follower insights & engagement rates</li>
                    <li>✔ Recommendations for improvement</li>
                  </ul>
                  <p>
                    Transparency matters! Our reports provide clear, actionable
                    insights for continuous success.
                  </p>
                </div>

                <div className="service-section mb-20">
                  <h3>8. Guaranteed Growth Strategy</h3>
                  <p>We focus on real, measurable growth for your business:</p>
                  <ul>
                    <li>
                      📌 For new accounts – Achieve 13% growth in 3 months
                    </li>
                    <li>
                      📌 For existing accounts – Boost engagement & reach by 60%
                    </li>
                  </ul>
                  <p>
                    Our strategic approach ensures sustainable, long-term
                    success for your brand.
                  </p>
                </div>
              </div>
            </div>

            {/* Sidebar */}
            <div className="col-xl-4 col-lg-6 col-md-12">
              <div className="service__details__left">
                <div
                  className="sidebar__widget_network"
                  style={{ backgroundImage: `url(${basebg})` }}
                >
                  {/* <h3>WhatsApp Services</h3> */}
                  <video
                    controls
                    style={{ width: "100%", marginRight: "10px" }}
                  >
                    <source src={videoSource} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="sidebar__widget">
                  <div className="all__service">
                    <h4>All Services</h4>
                  </div>

                  <ul className="service__details__1__cat">
                    {renderServices()}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterOne />
    </>
  );
};

export default CloudComputing;
